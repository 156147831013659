<template>
  <div id="main">
    <header>
      <div class="navbar navbar-dark shadow-sm">
        <div class="container d-flex justify-content-between">
          <div style="display: inline;">
            <router-link :to="'/'" class="nav-item " style="color:white; text-decoration: none;">Nabavke</router-link>
            <router-link :to="'/novinari'" class="nav-item " style="color:white; margin-left:10px; text-decoration: none;">Novinari</router-link>
           </div>
            <a class="nav-item nav-link" style="color:white;" href="#" @click="logout()">Odjava</a>
        </div>
      </div>
    </header>
    <div class="container-fluid">
      <div class="row justify-content-center mt-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
          <h3 style="color:white;">Kodovi / Korisnici</h3>
        </div>
      </div>
      <div class="row justify-content-center">
			<div class="card mt-4 col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12 history">
				<div class="card-body" style="font-size:15px; text-align:center;">
						 <v-client-table :data="tableData" :columns="tableColumns" :options="tableOptions">
                <span slot="created" slot-scope="props"> {{moment(props.row.created).format("DD-MM-YY  HH:mm:ss")}}</span>
                <span slot="checkedDate" slot-scope="props">
                  <span v-if="props.row.checked == 'da'">{{moment(props.row.checkedDate).format("DD-MM-YY  HH:mm:ss")}}</span>
                  <span v-if="props.row.checked == 'ne'">Nije čekirano</span>
                </span>
						 </v-client-table>
				</div>
			</div>
		</div>
    </div>
  </div>
</template>

<script>
 const axios = require('axios')
 	import moment from 'moment'
  import Spinner from 'vue-simple-spinner'


  export default {
    name: 'Novinari',
    components:{
      Spinner
    },
    data() {
      return {
        code: '',

        tableColumns: ['id', 'name', 'location', 'ip',  'code', 'created', 'barmen', 'checkedDate'],
        tableData:[],
          tableOptions: {
            headings: {
              id: 'ID',
              name: 'Ime',
              location: 'Prebivalište',
              ip: 'IP',
              code: 'Kod',
              created: 'Kreirano',
              barmen: 'Barmen',
              checkedDate: 'Čekirano'
            },
            filterable: ['id', 'name', 'location', 'ip',  'code', 'created', 'barmen','checkedDate'],
            sortable: ['id', 'name', 'location', 'ip',  'code', 'created', 'barmen', 'checkedDate'],
            perPage: 10
        },
        moment: moment
      }
    },
    methods: {
      getNovinari: async function(){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token')
        let response = await axios.get('http://localhost:3000/api/korisnik/novinari')
          this.tableData = response.data
      },
      logout: function(){
        localStorage.removeItem('jwt_token');
        this.$router.push('/login')
      }
    },
    mounted(){
      this.getNovinari();
    }
  }

</script>


<style>
  .navbar{
     background: #34626c;
  }

  h1 {
    font-family: 'Arbutus', cursive;
    color: #fff;
    text-transform: uppercase;
    font-weight: normal;
}


.form-control[readonly]{
  color:black;
}
.spinner-border{
  color: white !important;
}
</style>

