<template>

    <div class="container-fluid">

     <div class="row justify-content-center mt-5 mb-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
          <h1 style="color: #34626c">Prijava</h1>
        </div>
      </div>

      <div class="row justify-content-center mt-5">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center">
              <div class="form-group">
                <label for="email" style="color:#34626c;">Email</label>
                <input type="text" class="form-control" id="email" placeholder="Uneti email" v-model="email">
            </div>
		</div>
    </div>
    <div class="row justify-content-center mt-1">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center">
              <div class="form-group">
                <label for="password" style="color:#34626c;">Lozinka</label>
                <input type="password" class="form-control" id="password" placeholder="Uneti šifru" v-model="password">
            </div>
		</div>
    </div>
    <div class="row justify-content-center mt-4">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center">
            <button class="btn btn-red btn-block" type="button" v-if="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"</span>
            Proveravam...
          </button>
        <button class="btn btn-block" style="background-color:#34626c; color: white;" @click="login()" v-else>Prijavi se</button>
		</div>
    </div>
  </div>
</template>

<script>
 const axios = require('axios')

  export default {
    name: 'Main',
    data() {
      return {
          email: '',
          password: '',
          loading: false
      }
    },
    methods:{
        login: function(){

            localStorage.setItem('jwt_token', "123456")
            this.$router.push('/')
            return;
            var self = this
            self.loading = true
            axios.post(process.env.API_ROOT + '/api/auth/login', {
                email: self.email,
                password: self.password
		  	})
		  	.then(function (response) {
                self.loading = false
				if(response.data.status == "error") return self.$swal({ type: 'error', text: 'Pogresan email ili lozinka!', confirmButtonColor: '#EE1E32'})
		    	if(response.data.status == "ok") {
						localStorage.setItem('jwt_token', response.data.token)
						self.$router.push('/')
					}
		  	})
		  	.catch(function (error) {
				self.loading = false;
		    	self.$swal({ type: 'error', text: 'Server not responding!', confirmButtonColor: '#EE1E32'})
		  	})
    }
  }
}
</script>


<style>

</style>

