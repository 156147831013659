<template>
    <b-container fluid>
        <b-row  v-if="loading">
            <b-col sm="12" md="12"  lg="12" xl="12">
                <Spinner line-fg-color="#34626c" size="large" :line-size="5" style="margin-top:35vh;" v-if="loading"/>
            </b-col>
        </b-row>
        <b-row class="mt-2"  v-if="!loading">
            <b-col sm="12" md="12"  lg="12" xl="12" style="text-align:center;">
                <span> <b>Unesite parametre za pretragu nabavki: </b></span>
            </b-col>
            <b-col sm="12" md="4"  lg="2" xl="2">
                <div role="group">
                    <b-form-input v-model="nazivDokumenta"  id="nazivDokumenta" placeholder="Uneti naziv dokumenta" class="mt-3"></b-form-input>
                </div>
            </b-col>
            <b-col sm="12" md="4"  lg="2" xl="2">
                <div role="group">
                    <b-form-input v-model="nazivNarucioca"  id="nazivNarucioca" placeholder="Uneti naziv naručioca" class="mt-3"></b-form-input>
                </div>
            </b-col>
            <b-col col sm="12" md="4" lg="2" xl="2">
                <div role="group">
                    <vue-typeahead-bootstrap
                            v-model="maticniBrojDobavljaca"
                            :data="maticniBrojDobavljacaList"
                            :minMatchingChars="2"
                            placeholder="Uneti matični broj dobavljača"
                            class="mt-3"
                    />
                </div>
            </b-col>
            <b-col sm="12" md="4"  lg="2" xl="2">
                <div role="group">
                    <vue-typeahead-bootstrap
                            v-model="pibDobavljaca"
                            :data="pibDobavljacaList"
                            :minMatchingChars="2"
                            placeholder="Uneti PIB dobavljača"
                            class="mt-3"
                    />
                </div>
            </b-col>
            <b-col  sm="12" md="4"  lg="2" xl="2">
                <div role="group">
                   <vue-typeahead-bootstrap
                           v-model="maticniBrojNarucioca"
                           :data="maticniBrojNaruciocaList"
                           :minMatchingChars="2"
                           placeholder="Uneti matični broj naručioca"
                           class="mt-3"
                   />
               </div>
           </b-col>
           <b-col sm="12" md="4"  lg="2" xl="2">
               <div role="group">
                   <vue-typeahead-bootstrap
                           v-model="pibNarucioca"
                           :data="pibNaruciocaList"
                           :minMatchingChars="2"
                           placeholder="Uneti PIB naručioca"
                           class="mt-3"
                   />
                </div>
            </b-col>
            <b-col sm="12" md="4"  lg="2" xl="2">
                <div role="group">
                    <vue-typeahead-bootstrap
                            v-model="sifraNabavke"
                            :data="sifraNabavkeList"
                            :minMatchingChars="2"
                            placeholder="Uneti šifru nabavke"
                            class="mt-3"
                    />
                </div>
            </b-col>
            <b-col sm="12" md="4"  lg="2" xl="2">
                <div role="group">
                    <b-form-input v-model="ugovorenaVrednost"  id="ugovorenaVrednost" placeholder="Uneti ugovorenu vrednost" class="mt-3"></b-form-input>
                </div>
            </b-col>
            <b-col  sm="12" md="4"  lg="2" xl="2">
                <div role="group">
                  <vue-typeahead-bootstrap
                          v-model="mesto"
                          :data="mestoList"
                          :minMatchingChars="1"
                          :maxMatches="500"
                          :showOnFocus="true"
                          placeholder="Uneti mesto"
                          class="mt-3"
                  />
                  </div>

              </b-col>
              <b-col  xs="12" sm="12" md="4" lg="2" xl="2">
                  <div role="group">
                     <vue-typeahead-bootstrap
                             v-model="godina"
                             :data="godinaList"
                             :minMatchingChars="1"
                             :showOnFocus="true"
                             placeholder="Uneti godinu"
                             class="mt-3"
                     />
                 </div>
             </b-col>
             <b-col col lg="2" xl="2">
                 <b-button class="btn-block mt-3" style="background-color: #b40515 !important; border-color: #b40515 !important;" @click="resetujPolja()">Resetuj polja</b-button>
             </b-col>
             <b-col col lg="2" xl="2">
                 <b-button class="btn-block mt-3" style="background-color: #34626c !important; border-color: #34626c !important;" @click="pretraziNabavke()">Pretraži</b-button>
             </b-col>
         </b-row>
         <b-row class="justify-content-center mt-3"  v-if="!loading">
             <b-card class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <v-client-table :data="tableData" :columns="tableColumns" :options="tableOptions">
                    <span slot="link" slot-scope="props">  <a :href="props.row.link" target="_blank">Otvori link</a></span>
                </v-client-table>
             </b-card>
         </b-row>
     </b-container>
 </template>

 <script>
  const axios = require('axios')
  import moment from 'moment'
  import Spinner from 'vue-simple-spinner'
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token')

   export default {
     name: 'Nabavke',
     components:{
         Spinner
     },
     data() {
       return {
           loading: true,
           nazivDokumenta: "",
           nazivNarucioca: "",
           maticniBrojDobavljaca: null,
           maticniBrojDobavljacaList: [],
           pibDobavljaca: null,
           pibDobavljacaList:[],
           sifraNabavke: null,
           sifraNabavkeList:[],
           ugovorenaVrednost: null,
           maticniBrojNarucioca: null,
           maticniBrojNaruciocaList: [],
           pibNarucioca: null,
           pibNaruciocaList: [],
           mesto: null,
           mestoList: [],
           godina: null,
           godinaList: [],
           tableColumns: ['id', 'nazivDokumenta', 'nazivNarucioca', 'maticniBrojDobavljaca', 'pibDobavljaca', 'sifraNabavke', 'ugovorenaVrednost', 'maticniBrojNarucioca', 'pibNarucioca', 'link', 'mesto', 'godina' ],
           tableData:[],
           tableOptions: {
               headings: {
                   id: 'ID',
                     nazivDokumenta: 'Naziv dokumenta',
                     nazivNarucioca: 'Naziv naručioca',
                     maticniBrojDobavljaca: 'Mat. br. dobavljača',
                     pibDobavljaca: 'PIB dobavljača',
                     sifraNabavke: 'Šifra nabavke',
                     ugovorenaVrednost: 'Ugovorena vrednost',
                     maticniBrojNarucioca: 'Mat. br. naručioca',
                     pibNarucioca: 'PIB naručioca',
                     link: 'Link nabavke',
                     mesto: 'Mesto',
                     godina: 'Godina'
               },
               sortable: ['godina'],
               filterable: false,
               perPageValues: [],
               perPage: 50
           },
           moment: moment
       }
     },
     methods: {
       pretraziNabavke: async function(){
           this.loading = true;
           let params = {}

           if(this.nazivDokumenta) params.nazivDokumenta = this.nazivDokumenta
           if(this.nazivNarucioca) params.nazivNarucioca = this.nazivNarucioca
           if(this.maticniBrojDobavljaca) params.maticniBrojDobavljaca = this.maticniBrojDobavljaca
           if(this.maticniBrojNarucioca) params.maticniBrojNarucioca = this.maticniBrojNarucioca
           if(this.pibNarucioca) params.pibNarucioca = this.pibNarucioca
           if(this.pibDobavljaca) params.pibDobavljaca = this.pibDobavljaca
           if(this.sifraNabavke) params.sifraNabavke = this.sifraNabavke
           if(this.ugovorenaVrednost) params.ugovorenaVrednost = this.ugovorenaVrednost
           if(this.mesto) params.mesto = this.mesto
           if(this.godina) params.godina = this.godina

           let response = await axios.get('http://159.69.87.213:8085/api/korisnik/nabavke',  { params })
           this.tableData = response.data
           this.loading = false;
       },
         resetujPolja(){
             this.nazivDokumenta = ""
             this.nazivNarucioca =  ""
             this.maticniBrojDobavljaca =  null
             this.pibDobavljaca =  null
             this.sifraNabavke =  null
             this.ugovorenaVrednost =  null
             this.maticniBrojNarucioca =  null
             this.pibNarucioca =  null
             this.mesto =  null
             this.godina =  null
         },
         prefill: async function(){
             let response = await axios.get('http://159.69.87.213:8085/api/korisnik/prefill-nabavke')

             this.maticniBrojDobavljacaList = response.data.maticniBrojDobavljaca;
             this.pibDobavljacaList = response.data.pibDobavljaca;
             this.sifraNabavkeList = response.data.sifraNabavke;

             this.maticniBrojNaruciocaList = response.data.maticniBrojNarucioca;
             this.pibNaruciocaList = response.data.pibNarucioca;
             this.mestoList = response.data.mesto;
             this.godinaList = response.data.godina;

         }
     },
     async mounted(){
         await this.prefill();
         await this.pretraziNabavke();
         this.loading = false;
     }
   }

 </script>


 <style>


   .VueTables{
       overflow-x: auto !important;
   }

 </style>

