<template>
    <b-container fluid>
        <b-row  v-if="loading">
            <b-col sm="12" md="12"  lg="12" xl="12">
                <Spinner line-fg-color="#34626c" size="large" :line-size="5" style="margin-top:35vh;" v-if="loading"/>
            </b-col>
        </b-row>
        <b-row class="mt-2"  v-if="!loading">
            <b-col sm="12" md="12"  lg="12" xl="12" style="text-align:center;">
                <span> <b>Statistika </b></span>
            </b-col>
        </b-row>
        <b-row class="justify-content-center mt-3"  v-if="!loading">
            <b-card class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 mx-2 my-2" sub-title="Pretraga po parametru">
                <b-row class="mt-3">
                    <b-col sm="12" md="4"  lg="3" xl="3">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-1" :options="pretragaPoOptions" v-model="pretraziPo" ></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4"  lg="3" xl="3">
                        <vue-typeahead-bootstrap
                                v-model="maticniBrojDobavljaca"
                                :data="maticniBrojDobavljacaList"
                                :minMatchingChars="2"
                                placeholder="Uneti matični broj dobavljača"
                                class="mb-1"
                                v-if="pretraziPo=='maticniBrojDobavljaca'"
                        />
                        <vue-typeahead-bootstrap
                                v-model="pibDobavljaca"
                                :data="pibDobavljacaList"
                                :minMatchingChars="2"
                                placeholder="Uneti PIB dobavljača"
                                class="mb-1"
                                v-if="pretraziPo=='pibDobavljaca'"
                        />
                        <vue-typeahead-bootstrap
                                v-model="maticniBrojNarucioca"
                                :data="maticniBrojNaruciocaList"
                                :minMatchingChars="2"
                                placeholder="Uneti matični broj naručioca"
                                class="mb-1"
                                v-if="pretraziPo=='maticniBrojNarucioca'"
                        />
                        <vue-typeahead-bootstrap
                                v-model="pibNarucioca"
                                :data="pibNaruciocaList"
                                :minMatchingChars="2"
                                placeholder="Uneti PIB naručioca"
                                class="mb-1"
                                v-if="pretraziPo=='pibNarucioca'"
                        />
                    </b-col>
                    <b-col sm="12" md="4" lg="3" xl="3">
                        <b-button class="btn-block mb-1" style="background-color: #34626c !important; border-color: #34626c !important;" @click="pretraziUgovoreneVrednosti()">Pretraži</b-button>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col sm="12" md="12"  lg="12" xl="12">
                        <div>
                            <b-table striped hover :items="pretragaUgovoreneVrednosti"></b-table>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 mx-2 my-2" sub-title="Pretraga po mestu">
                <b-row  class="mt-3">
                    <b-col sm="12" md="4"  lg="3" xl="3">
                        <vue-typeahead-bootstrap
                                v-model="mesto"
                                :data="mestoList"
                                :minMatchingChars="1"
                                placeholder="Izabrati mesto"
                                class="mb-1"
                                :maxMatches="500"
                                :showOnFocus="true"
                        />
                    </b-col>
                    <b-col sm="12" md="4"  lg="3" xl="3">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-1" :options="pretragaPoOptions2" v-model="pretraziPo2" ></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4"  lg="3" xl="3">
                        <b-form-select id="inline-form-custom-select-pref" class="mb-1" :options="sortirajPoOptions" v-model="sortirajPo" ></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4" lg="3" xl="3">
                        <b-button class="btn-block mb-1" style="background-color: #34626c !important; border-color: #34626c !important;" @click="pretraziPoMestu()">Pretraži</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="12"  lg="12" xl="12">
                        <div>
                            <b-table striped hover :items="pretragaPoMestu"></b-table>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-row>
    </b-container>
</template>

<script>
    const axios = require('axios')
    import moment from 'moment'
    import Spinner from 'vue-simple-spinner'
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token')

    export default {
        name: 'Statistika',
        components:{
            Spinner
        },
        data() {
            return {
                loading: true,
                moment: moment,
                maticniBrojDobavljaca: null,
                maticniBrojDobavljacaList: [],
                pibDobavljaca: null,
                pibDobavljacaList:[],
                maticniBrojNarucioca: null,
                maticniBrojNaruciocaList: [],
                pibNarucioca: null,
                pibNaruciocaList: [],
                mesto: null,
                mestoList: [],
                pretraziPo: null,
                pretraziPo2: null,
                sortirajPo: null,
                pretragaUgovoreneVrednosti:[],
                pretragaPoMestu:[],
                pretragaPoOptions: [
                    { value: null, text: 'Pretrazi po...', disabled: true },
                    { value: 'maticniBrojDobavljaca', text: 'Matični broj dobavljača' },
                    { value: 'pibDobavljaca', text: 'PIB dobavljaca' },
                    { value: 'maticniBrojNarucioca', text: 'Matični broj narucioca' },
                    { value: 'pibNarucioca', text: 'PIB narucioca' }
                ],
                pretragaPoOptions2: [
                    { value: null, text: 'Pretrazi po...', disabled: true },
                    { value: 'maticniBrojDobavljaca', text: 'Matični broj dobavljača' },
                    { value: 'maticniBrojNarucioca', text: 'Matični broj narucioca' }
                ],
                sortirajPoOptions: [
                    { value: null, text: 'Sortiraj po...', disabled: true },
                    { value: 'najvecaSuma', text: 'Najvećoj sumi' },
                    { value: 'brojuNabavki', text: 'Broju nabavki' }
                ]
            }
        },
        methods: {
            pretraziUgovoreneVrednosti: async function(){
                this.loading = true;
                if(this.pretraziPo == null) return;
                if(this.maticniBrojDobavljaca == null && this.pibDobavljaca == null && this.maticniBrojNarucioca == null && this.pibNarucioca == null ) return;
                let params = {}
                params.tip = "pretraga"
                if(this.pretraziPo == "maticniBrojDobavljaca")  params.maticniBrojDobavljaca = this.maticniBrojDobavljaca
                if(this.pretraziPo == "pibDobavljaca") params.pibDobavljaca = this.pibDobavljaca
                if(this.pretraziPo == "maticniBrojNarucioca")  params.maticniBrojNarucioca = this.maticniBrojNarucioca
                if(this.pretraziPo == "pibNarucioca")  params.pibNarucioca = this.pibNarucioca
                let response = await axios.get('http://159.69.87.213:8085/api/korisnik/statistika',  { params })
                this.pretragaUgovoreneVrednosti = response.data
                this.loading = false;
            },
            pretraziPoMestu: async function(){
                this.loading = true;
                if(this.sortirajPo == null || this.mesto == null || this.pretraziPo2 == null) return;
                let params = {}
                params.tip = "poMestu"
                params.mesto = this.mesto
                params.sortirajPo = this.sortirajPo
                params.pretraziPo = this.pretraziPo2
                let response = await axios.get('http://159.69.87.213:8085/api/korisnik/statistika',  { params })
                this.pretragaPoMestu = response.data
                this.loading = false;
            },

            prefill: async function(){
                let response = await axios.get('http://159.69.87.213:8085/api/korisnik/prefill-nabavke')

                this.maticniBrojDobavljacaList = response.data.maticniBrojDobavljaca;
                this.pibDobavljacaList = response.data.pibDobavljaca;
                this.maticniBrojNaruciocaList = response.data.maticniBrojNarucioca;
                this.pibNaruciocaList = response.data.pibNarucioca;
                this.mestoList = response.data.mesto;

            }
        },
        async mounted(){
            await this.prefill();
            //await this.pretraziUgovoreneVrednosti();
            this.loading = false;
        }
    }

</script>


<style>

</style>

