import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {ClientTable, Event} from 'vue-tables-2'
import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';



Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

const options = {
  confirmButtonColor: '#EE1E32',
  cancelButtonColor: '#ff7674'
}

Vue.config.productionTip = false

Vue.use(VueSweetalert2, options)
Vue.use(ClientTable, {}, false, 'bootstrap4');

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
