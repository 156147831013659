import Vue from 'vue'
import Router from 'vue-router'
import Nabavke from '@/components/Nabavke'
import Statistika from '@/components/Statistika'
import Konzorcijumi from '@/components/Konzorcijumi'
import Nalozi from '@/components/Nalozi'
import Login from '@/components/Login'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/nabavke'
    },
    {
      path: '/nabavke',
      name: 'Nabavke',
      component: Nabavke
    },
      {
          path: '/statistika',
          name: 'Statistika',
          component: Statistika
      },
      {
          path: '/Konzorcijumi',
          name: 'Konzorcijumi',
          component: Konzorcijumi
      },
    {
      path: '/nalozi',
      name: 'Nalozi',
      component: Nalozi
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
  ]
})

/*
router.beforeEach((to, from, next) => {
    let isAuth = localStorage.getItem("jwt_token")
    if(isAuth) {
        next();
    }
    else {
        if(to.path == "/login") next()
        else next('/login');
    }

});
*/

export default router;
