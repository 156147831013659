<template>
  <div id="app">
    <div id="main">
      <header>
        <div class="navbar navbar-dark shadow-sm">
          <div class="container d-flex justify-content-between">
            <div style="display: inline;">
              <router-link :to="'/'" class="nav-item " style="color:white; text-decoration: none;">Nabavke</router-link>
              <router-link :to="'/statistika'" class="nav-item " style="color:white; margin-left:10px; text-decoration: none;">Statistika</router-link>
              <router-link :to="'/konzorcijumi'" class="nav-item " style="color:white; margin-left:10px; text-decoration: none;">Konzorcijumi</router-link>
              <!--<router-link :to="'/nalozi'" class="nav-item " style="color:white; margin-left:10px; text-decoration: none;">Nalozi</router-link>-->
            </div>
            <!--<a class="nav-item nav-link" style="color:white;" href="#" @click="logout()">Odjava</a>-->
          </div>
        </div>
      </header>
    <router-view/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "app",
    methods: {
      logout: function () {
        localStorage.removeItem('jwt_token');
        this.$router.push('/login')
      }
    }
  }
</script>

<style>
  html, body, #app {
    height: 100%;
    min-height: 100%;
    background:#cfd3ce;
    font-family: 'Roboto', sans-serif;

  }
  .navbar{
    background: #34626c;
  }

</style>
